<script>
export default {
  props: {
    id: String,
    title: String,
    content: String,
  },
  data() {
    return {
      deleteFunction: null,
      showModal: false
    };
  },
  methods: {
    show(entity) {
      this.showModal = true;
      return new Promise((resolve) => {
        this.deleteFunction = () => resolve(entity);
      }).finally(() => {
        this.showModal = false;
      });
    },
    onDelete() {
      if (this.deleteFunction !== null) {
        this.deleteFunction();
      } else {
        this.$emit('onDelete');
      }
    }
  },
  validations: {
  }
};
</script>

<template>
  <div>
    <b-modal :id="id" centered :ok-title="this.$t('general.delete')" :cancel-title="this.$t('general.close')" v-model="showModal"
      @ok="onDelete()" ok-variant="danger">
      <div class="d-block text-center">
        <i class='bx bx-x-circle bx-tada bx-lg' style='color:#ff0000; size: 140px;'></i>
        <h4>{{ title }}</h4>
      </div>
      <div class="text-center">
        <p class="card-title-desc">
          {{ content }}
        </p>
      </div>
    </b-modal>
  </div>
</template>
